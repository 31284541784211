/* eslint-disable import/no-cycle */
/* eslint-disable max-len */

// Imports
import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import axios from 'axios';
import {resolveDistributor} from '../utils';

// CSS
import * as ProviderStyles from './css/Provider.module.css';

// SVG
import Logo from '../assets/images/bb-logo-sidebar.svg';
import Flag from '../assets/images/flag-icon.svg';
import Close from '../assets/images/icons/icon_close.svg';

// Components
import Nav from './Nav';
import Search from './Search';
import Form from './Form';
import Textarea from './Textarea';
import Input from './Input';
import Button from './Button';

// React context
export const myContext = React.createContext({});

// Email validation
const validateEmail = email => {
	const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return !emailRegex.test(email.toLowerCase());
};

const distributorArr = ['AU', 'AT', 'BY', 'BE', 'CN', 'CR', 'DK', 'EE', 'FI', 'FR', 'DE', 'GR', 'HK', 'IL', 'IN', 'IT', 'KZ', 'LT', 'LU', 'LT', 'MO', 'MC', 'NL', 'NZ', 'PL', 'RU', 'ZA', 'KR', 'ES', 'IC', 'SE', 'CH', 'TH', 'TR', 'UA', 'SA', 'AE', 'OM', 'QA', 'KW', 'BH', 'TW', 'PT'];

// ======== Component ========
const ProviderComponent = props => {
	const {
		children,
	} = props;

	// State
	const [data, setData] = useState(null);
	const [ukPressData, setUKPressData] = useState(null);
	const [tags, setTags] = useState(null);
	const [mobileNavOpen, setMobileNavOpen] = useState(false);
	const [searchValue, setSearchValue] = useState('');
	const [loading, setLoading] = useState(true);

	// Distributors
	const [distributor, setDistributor] = useState(false);

	useEffect(() => {
		resolveDistributor(
			distributorArr,
			setDistributor
		);
	}, [setDistributor]);

	// Modal state
	const [modalState, setModalState] = useState({
		open: false,
		productHasBeenOpened: false,
		productUrl: '',
		content: '', // "info", "search", "product", "inquiries" for different modal contents
	});

	// Form state
	const [nameInput, setNameInput] = useState({ value: '', error: false });
	const [emailInput, setEmailInput] = useState({ value: '', error: false });
	const [textArea, setTextArea] = useState({ value: '', error: false });
	const [formMessage, setFormMessage] = useState('');
	const [buttonDisabled, setButtonDisabled] = useState(false);

	// Fetch the data and store it in state
	const fetchedData = async() => {
		const d = await axios.get('https://content.pilates.com/wp-json/wp/v2/uk/');
		setData(() => d);
	};

	// Fetch UK press data from wordpress
	const fetchUKPressData = async() => {
		const pressData = await axios.get('https://content.pilates.com/wp-json/wp/v2/uk_press/');
		setUKPressData(() => pressData);
	};

	// Fetch the tags and store it in state
	const fetchedTags = async() => {
		const t = await axios.get('https://content.pilates.com/wp-json/wp/v2/tags?per_page=100');
		let tagArray = []; // [{ id: #, name: "" }, ...]
		for (let i = 0; i < t.data.length; i++) {
			tagArray = [...tagArray, { id: t.data[i].id, name: t.data[i].name }];
		}
		setTags(() => tagArray);
	};

	// Trigger fetchedData() and fetchedTags() on component mount, as well as check cookie
	useEffect(() => {
		fetchedData();
		fetchedTags();
		fetchUKPressData();
	}, []);

	// Handle Loading state
	useEffect(() => {
		setLoading(() => true);

		const isPressRoom = window.location.pathname.includes('press-room');
		if (isPressRoom) return ukPressData && setLoading(() => false);

		if (data && tags) return setLoading(() => false);
	}, [data, ukPressData, tags]);

	// Open/close mobile nav
	const handleMobileNavOpen = () => {
		setMobileNavOpen(!mobileNavOpen);
	};

	// Open/close modal
	const handleModalOpen = value => {
		setModalState({
			...modalState,
			...value,
		});
		if (value.open) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'auto';
		}
	};

	// Search from header
	const handleModalSearch = val => {
		setSearchValue(val);

		// Closes modal if searching from mobile
		setModalState({ ...modalState, open: false });
		document.body.style.overflow = 'auto';

		// Navigates to the search page (does nothing if already there)
		navigate('/search');
	};

	// Form submission
	const formSubmitAction = () => {
		setButtonDisabled(true);
		setFormMessage('Sending...');
		let error = false;

		// Validate fields
		if (nameInput.value.length <= 0) {
			setNameInput({ ...nameInput, error: true });
			error = true;
		}
		if (validateEmail(emailInput.value)) {
			setEmailInput({ ...emailInput, error: true });
			error = true;
		}
		if (textArea.value.length <= 0) {
			setTextArea({ ...textArea, error: true });
			error = true;
		}

		// Return if validation fails
		if (error) {
			setButtonDisabled(false);
			setFormMessage('');
			return;
		}

		// Send email
		const url = 'https://sites.pilates.com/email-webhooks/uk';
		const headers = {
			'Content-Type': 'application/json',
		};
		const requestOptions = {
			method: 'POST',
			headers,
			body: JSON.stringify({
				fullname: nameInput.value,
				email: emailInput.value,
				message: textArea.value,
			}),
		};

		fetch(url, requestOptions)
			.then(response => {
				if (response.status !== 200) {
					throw Error(setFormMessage(`POST error, status code: ${ response.status }`));
				}
				setFormMessage('Thank you, your message has been sent.');
			})
			.catch(err => {
				setFormMessage(err);
				setButtonDisabled(false);
			});
	};

	function reset() {
		setNameInput({ value: '', error: false });
		setEmailInput({ value: '', error: false });
		setTextArea({ value: '', error: false });
		setFormMessage('');
		setButtonDisabled(false);
	}

	if (loading) {
		return (
			<p className={`body-text ${ ProviderStyles.loadingText }`}>
				<span style={{ display: 'block' }}>Loading</span>
				<Logo height={'120px'} style={{ marginTop: '1rem' }} />
			</p>
		);
	}

	return (
		<myContext.Provider
			value={{
				data,
				tags,
				modalState,
				searchValue,
				setSearchValue,
				handleModalOpen,
				handleModalSearch,
				handleMobileNavOpen,
				distributor,
				ukPressData,
			}}
		>
			<div>
				<div role={'banner'} className={`flex ${ ProviderStyles.skinnyBanner }`}>
					{/* <p>
                                Contact us • <a href="mailto:info@pilates.com?subject=UK Warehouse website query">info@pilates.com</a> or{" "}
                                <a href="tel:0800 014 8207">0800 014 8207</a> or{" "}
                                <a href="tel:0044 8000148207">0044 8000148207</a> (from Republic of Ireland)
                            </p> */}
					<p>
						Need your Balanced Body equipment maintained or repaired? Service available starting at $235.00/hour.
						{` `}
						<b><a href={'mailto:katherine.ryzhova@pilates.com?subject=Maintenance/Repair%20Service%20Inquiry'}>Inquire for details.</a></b>
					</p>
					{/* <p>25% DISCOUNT ON SELECTED EQUIPMENT- <a href="/sale">click here</a></p> */}
				</div>

				{/* Navigation */}
				<div
					className={`
                                ${ ProviderStyles.navContainer }
                                ${ mobileNavOpen ? ProviderStyles.navContainerOpen : null }
                              `}
				>
					<Nav searchValue={searchValue} handleMobileNavOpen={handleMobileNavOpen} />
				</div>
			</div>

			{/* Modal */}
			{modalState.open && (
				<div className={`flex ${ ProviderStyles.modal }`}>
					<button
						aria-label={'close'}
						onClick={() => {
							handleModalOpen({ ...modalState, open: false });
							reset();
						}}
						className={ProviderStyles.modalBg}
					/>
					<div className={`flex ${ ProviderStyles.modalBox }`}>
						{/* Product modal */}
						{modalState.content === 'product' && (
							<>
								<div className={`flex flex-row w100 ${ ProviderStyles.modalTextContainer }`}>
									<Logo className={ProviderStyles.modalLogo} />
									<p className={'h4 w100'}>
										You’re about to leave this site and enter pilates.com
									</p>
								</div>

								<p className={`body-text w100 ${ ProviderStyles.modalDollarsText }`}>
									All products on pilates.com are in US dollars and do not include duties,
									taxes(VAT), and shipping.
								</p>

								<div className={`flex flex-row ${ ProviderStyles.modalButtonContainer }`}>
									<a
										href={modalState.productUrl}
										target={'_blank'}
										rel={'noreferrer'}
										className={`flex flex-row flex-jc-sb ${ ProviderStyles.usaLink }`}
									>
										<p className={`body-text ${ ProviderStyles.pilatescomText }`}>
											Go to pilates.com
										</p>
										<Flag className={ProviderStyles.flag} />
									</a>
									<button
										onClick={() => {
											handleModalOpen({ ...modalState, open: false });
										}}
										className={`link-text ${ ProviderStyles.modalClose }`}
									>
										Close
									</button>
								</div>
							</>
						)}

						{/* How to purchase info */}
						{modalState.content === 'info' && (
							<>
								<div className={`flex flex-ai-fs body-text ${ ProviderStyles.modalInfo }`}>
									<a href={'mailto:info@pilates.com'} className={'link-text'}>
										info@pilates.com
									</a>
									<a href={'tel:0800 014 8207'} className={'link-text'}>
										0800 014 8207
									</a>
									<p>We will respond within 24 hours.</p>
									<p style={{ marginTop: '1rem' }}>Thank you!</p>
								</div>
								<button
									onClick={() => {
										handleModalOpen({ ...modalState, open: false });
									}}
									className={`link-text ${ ProviderStyles.modalClose }`}
									style={{ alignSelf: 'flex-start' }}
								>
									Close
								</button>
							</>
						)}

						{/* Mobile search */}
						{modalState.content === 'search' && (
							<>
								<div
									className={`flex flex-row flex-jc-sb w100 ${ ProviderStyles.modalBoxTopContainer }`}
								>
									<p className={`h2 ${ ProviderStyles.modalSearchTitle }`}>Search</p>
									<div className={'flex'}>
										<button
											aria-label={'close'}
											onClick={() => {
												handleModalOpen({ ...modalState, open: false });
											}}
											className={`flex flex-ai-fe w100 ${ ProviderStyles.closeMenuContainer }`}
										>
											<Close className={ProviderStyles.close} />
										</button>
									</div>
								</div>
								<Search searchAction={handleModalSearch} maxWidth={'400px'} />
							</>
						)}

						{/* Inquiries search */}
						{modalState.content === 'inquiries' && (
							<>
								{formMessage !== 'Thank you, your message has been sent.' && (
									<>
										<div
											className={`flex flex-row flex-jc-sb w100 ${ ProviderStyles.modalBoxTopContainerInquiry }`}
										>
											<p className={`h2 ${ ProviderStyles.modalSearchTitle }`}>
												Have a question?
											</p>
											<div className={'flex'}>
												<button
													aria-label={'close'}
													onClick={() => {
														handleModalOpen({ ...modalState, open: false });
														reset();
													}}
													className={`flex flex-ai-fe w100 ${ ProviderStyles.closeMenuContainer }`}
												>
													<Close className={ProviderStyles.close} />
												</button>
											</div>
										</div>

										<Form submitAction={formSubmitAction}>
											<Input
												label={'Name'}
												value={nameInput.value}
												error={nameInput.error}
												errorMessage={'Please enter your name.'}
												onChange={e => {
													setNameInput({ value: e.target.value, error: false });
												}}
											/>
											<Input
												label={'Email'}
												value={emailInput.value}
												error={emailInput.error}
												errorMessage={'Please enter a valid email.'}
												onChange={e => {
													setEmailInput({ value: e.target.value, error: false });
												}}
											/>
											<Textarea
												label={'Message'}
												value={textArea.value}
												error={textArea.error}
												errorMessage={'Please enter your message.'}
												onChange={e => {
													setTextArea({ value: e.target.value, error: false });
												}}
											/>
											<Button
												text={'Send Message'}
												fullWidth
												type={'button'}
												submit
												disabled={buttonDisabled}
											/>
										</Form>
									</>
								)}

								{/* Message */}
								<p className={`body-text w100 ${ ProviderStyles.formMessage }`}>{formMessage}</p>
							</>
						)}
					</div>
				</div>
			)}

			{/* Rest of content */}
			{children}
		</myContext.Provider>
	);
};

// ======== Component ========
const Provider = ({ element }) => {
	return <ProviderComponent>{element}</ProviderComponent>;
};

export default Provider;
